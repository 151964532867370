<template>
  <div style="background-color: #f6f6f6">
    <van-search
      v-model="value"
      shape="round"
      placeholder="请输入搜索关键词"
      @search="search"
    >
      <template #left>
        <van-icon name="arrow-left" @click="$router.back(-1)" class="arrow" />
      </template>
    </van-search>
    <div style="height: 46px"></div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="无更多搜索结果"
      @load="onLoad"
      :immediate-check="false"
    >
      <goods-two :value="goods"></goods-two>
    </van-list>
  </div>
</template>

<script>
import goodsTwo from "@/components/creator-box/goods-two";
import { getGoods } from "@/api/makerStore";
export default {
  name: "makerSearch",
  data() {
    return {
      value: null,
      shopId: null,
      current: 1,
      goods: [],
      total: 0,
      loading: false,
      finished: false,
    };
  },
  components: { goodsTwo },
  mounted() {
    this.shopId = this.$route.query.id;
    document.querySelector(".van-field__control").focus();
  },
  computed: {},
  methods: {
    getGoods() {
      const obj = {
        shopId: this.shopId,
        current: this.current,
        subject: this.value,
      };
      getGoods(obj).then((res) => {
        console.log(res);
        const result = res.data.data;
        this.goods.push(...result.records);

        this.total = result.total;

        this.loading = false;
        if (this.goods.length >= this.total) {
          this.finished = true;
        }
      });
    },
    search() {
      if (!this.value) {
        this.$toast("请输入搜索关键词");
      } else {
        this.goods = [];
        this.current = 1;
        this.finished = false;
        this.getGoods();
      }
    },
    // 获取商品数据
    onLoad() {
      this.current++;
      this.getGoods();
    },
  },
};
</script>

<style lang="less" scoped>
.arrow {
  padding: 0 16px 0 4px;
  font-size: 22px;
}
.van-search {
  padding: 6px 12px;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.van-list {
  height: calc(100vh - 46px);
  overflow: auto;
}
</style>